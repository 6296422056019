@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.1/font/bootstrap-icons.css");



.app-splashscreen
{
    /* background-color: white; */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 100;
    transition: left 1s;
    background-image: url('../spalsh_screen.png');
    background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100% 100%;
}

.bi{
    font-size: 25px;
    color: #f049ac;
}


.login-form {
    width: 100%;
    max-width: 350px;
    padding: 15px;
    margin: auto;
    height: 100%;
}
.sub-btn1 {
    background-color: #f049ac !important;
}
@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.title-zest {
    color: #f049ac;
}

.text-center {
    margin: 50px;
}

.Auth-container {
    /* margin: 0 100px; */
    padding-left: 90px;
    padding-top: 10px;
    padding-right: 0px;
    text-align: left;
}

.Logout-container {
    /* margin: 0 100px; */
    padding-left: 90px;
    padding-top: 10px;
    padding-right: 0px;
    text-align: left;
    z-index: 9999;
}
.teew {
}

.byebye {
    padding-left: 10px;
    padding-top: 10px;
    padding-right: 0px;
    text-align: left;
}
figure {
    width: 300px; /*container-width*/
    overflow: hidden; /*hide bounds of image */
    margin: 0; /*reset margin of figure tag*/
}
figure img {
    display: block; /*remove inline-block spaces*/
    /*add to other styles*/
    margin: 0;
    width: 57.777%;
}

.selpic_successpage {
    position: absolute;
    /* clip: rect(8px, 200px, 240px, 10px); */

    object-fit: cover;
    text-align: center;
    width: 240px;
    height: 250px;
    object-position: 0% 18%;
    border-radius: 20px;
    border-color: green;
    border-left: 320%;
}

.imgMale {
    width: 250px;
}
.imgMale_div {
    text-align: center;
    margin-left: 50px;
}

.idcard {
    width: 300px;
}

.selfie_page {
    text-align: center;
}

.selpic_selfiepage {
    position: absolute;
    /* clip: rect(8px, 200px, 240px, 10px); */

    object-fit: cover;
    margin-left: -32%;
    margin-top: 10%;
    text-align: center;
    width: 220px;
    height: 250px;
    object-position: 0% 18%;
    border-radius: 20px;
    /* border-color: green; */
    /* border-left: 320%; */
}

.column {
    float: left;
    width: 33.33%;
    padding: 5px;
}

/* Clear floats after image containers */
.row::after {
    content: '';
    clear: both;
    display: table;
}

.header {
    margin-top: -100px;
    margin-left: 50px;
    padding: 0.5em;
    height: 180px;
    color: white;
    text-align: center;
}

.classid_f {
    object-fit: cover;
    position: absolute;
    clip: rect(10px, 444px, 270px, 44px);
    /* transform: rotate(270deg); */
    /* zoom: 1.2; */
    margin-top: 15px;
    margin-left: -200px;
}

.classid_b {
    object-fit: cover;
    position: absolute;
    clip: rect(5px, 222px, 135px, 22px);
    margin-top: 25px;
    margin-left: -110px;
}

.button_id {
    display: inline-block;
    border-radius: 4px;
    background-color: #f049ac;
    border: none;
    color: #ffffff;
    text-align: center;
    font-size: 12px;
    padding: 5px;
    width: 100px;
    margin: 5px;
}

.camera-btn1 {
    margin-top: -100px;
    position: absolute;
    margin-left: 20px;
}

.id_logo {
    text-align: center;
    margin-left: 50px;
    margin-top: -100px;
}

.frcamera_bts {
    display: inline-block;
}
.button_selfie {
    color: white;
    position: relative;
    /* width: 75%; */
    min-width: 100px;
    max-width: 300px;
    margin-top: 0px;
    width: 180px;
    /* height: 200px; */
    padding: 6px 24px;
    background: #f049ac;
    opacity: 0.8;
    /* transform: rotate(90deg); */
}

.whichid {
    margin-left: 100px;
    /* padding: 100px; */
    margin-bottom: 90px;
    
}

.button_ids {
    color: white;
    position: relative;
    /* width: 75%; */
    min-width: 50px;
    max-width: 300px;
    margin-top: 0px;
    width: 180px;
    /* height: 200px; */
    padding: 6px 24px;
    background: #f049ac;
    opacity: 0.8;
    /* transform: rotate(90deg); */
    /* border-radius: 30%; */
}

/* .button {
    position: fixed;
    display: inline-block;
    border-radius: 4px;
    background-color: #00BFFF;
    border: none;
    color: #ffffff;
    text-align: center;
    font-size: 13px;
    padding: 5px;
    width: 120px;
} */

.margin1 {
    margin-bottom: 300px;
}

.margin2 {
    margin-bottom: 170px;
    /* margin-right: 100px; */
}
.margin3 {
    margin-bottom: 100px;
    /* margin-right: 100px; */
}

.classid_f_succ {
    object-fit: cover;
    position: absolute;
    clip: rect(5px, 240px, 148px, 22px);
    /* transform: rotate(270deg); */
    /* zoom: 1.2; */
    /* margin-top: -100px;
    margin-left: -130px; */
}

.classid_b_succ {
    object-fit: cover;
    position: absolute;
    clip: rect(5px, 240px, 148px, 22px);
    /* transform: rotate(270deg); */
    /* zoom: 1.2; */
    /* margin-top: 0px;
    margin-left: -130px; */
}

.button_id_succ {
    display: inline-block;
    position: relative;
    /* width: 75%; */
    min-width: 100px;
    max-width: 300px;
    margin-top: 0px;
    width: 180px;
    /* height: 200px; */
    padding: 6px 24px;
    background: #f049ac;
    opacity: 0.8;
    margin-left: 20px;
    margin-bottom: 20px;
}

.popi {
    /* z-index: 9999999999; */
    margin-top: -50px;
    margin-left: -140px;
    padding: 20px;
}

.popi1 {
    /* z-index: 9999999999; */
    margin-top: 200px;
    margin-left: 64px;
    padding: 20px;
}

.margin5 {
    padding-bottom: 130px;
}

.disable-link {
    pointer-events: none;
}

.link1 {
    color: #fff;
    text-decoration: none;
}
.link1:hover {
    color: #fff;
    text-decoration: none;
}

@media screen and (min-width: 768px) {
.whichid {
    margin-left: 100px;
    /* padding: 100px; */
    margin-bottom: 90px;
    
}
  }
